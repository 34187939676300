
::v-deep {
    .v-expansion-panel-header,
    .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 52px;
    }
    .v-expansion-panel-header {
        padding: 16px;
    }
    .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
        color: var(--v-grey-base);
    }

    .v-expansion-panel-content__wrap {
        padding: 0 16px;
    }
}
