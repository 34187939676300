
.v-input--checkbox,
.v-simple-checkbox,
.v-radio {
    ::v-deep {
        .v-input--selection-controls__ripple:before {
            display: none !important;
        }
    }
}
